<template>
  <v-container fluid>
    <FilterDrawer
      current-component="ncm-filter"
      @filter="filterNcm"
      @clear="clearFilter"
    />

    <Table
      :data="dataTable"
      :total-pages="dataTable.totalPages"
      :total-register="dataTable.totalRegister"
      @setCurrentPage="setCurrentPage"
      @search="search"
    >
      <template v-slot:tbody="{ item }">
        <td>{{ item.title }}</td>
        <td>{{ item.code }}</td>
        <td class="text-center">
          <StatusChip :current-status="item.status" />
        </td>
        <td>{{ formatLength(item.description) }}</td>
        <td class="text-right">
          {{ item.updated_at }}
        </td>
      </template>
    </Table>
  </v-container>
</template>
<script>
import { contextMenu, massAction } from '@/main.js'
import Table from '@/components/data-table/Table'
import FilterDrawer from '@/components/FilterDrawer'
import StatusChip from '@/components/data-table/StatusChip'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'

export default {
  components: {
    Table,
    FilterDrawer,
    StatusChip,
  },
  mixins: [checkProfileRoutePermission],
  data: () => ({
    currentSearch: '',
    dataTable: {
      title: 'NCM',
      new: 'ncm-new',
      edit: 'ncm-edit',
      context: 'ncm-context',
      massAction: 'ncm-mass-action',
      totalRegister: 1,
      totalPages: 1,
      headerButtons: {
        filter: true,
        new: true,
      },
      items: [],
      isLoading: false,
      headers: [
        { text: 'Nome', value: 'title' },
        { text: 'Código', value: 'code', align: 'center' },
        { text: 'Status', value: 'status', align: 'center' },
        { text: 'Descrição', value: 'description' },
        { text: 'Atualizado em', value: 'updated_at', align: 'end' },
      ],
    },
  }),
  created: function () {
    this.getNcm()

    contextMenu.$once('action', (type) => {
      if (type === 'ncms') {
        this.getNcm()
      }
    })
    massAction.$once('action', () => {
      this.getNcm()
    })
  },
  methods: {
    formatLength(value) {
      if (value != null) {
        return value.length > 150 ? value.slice(0, 150) + '...' : value
      } else {
        return ''
      }
    },
    getNcm(page = 1) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .get('ncms',{
          params: {
            page,
            search: this.currentSearch,
          },
        },{
          headers: {
            Authorization: `Beader ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.dataTable.totalPages = res.data.total_pages;
          this.dataTable.items = res.data.data
          this.dataTable.totalRegister = res.data.total_ncms;        
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    search(search) {
      this.currentSearch = search
      this.getNcm()
    },
    setCurrentPage(page) {
      this.getNcm(page)
    },
    filterNcm(filter) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .post('ncms/filter', filter)
        .then((res) => {
          this.dataTable.items = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    clearFilter() {
      this.getNcm()
    },
  },
}
</script>
